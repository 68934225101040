<template>
  <skin-thickness-template
    :skin-thickness="skinThickness"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import SkinThicknessTemplate from '@/modules/questionnaire/components/steps/common/identity/skin-thickness/SkinThicknessTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

const { field, requiredField } = fieldBuilder;

const FIELDS = [field('gender'), requiredField('skinThickness')];

export default {
  name: 'SkinThickness',
  components: {
    SkinThicknessTemplate
  },
  mixins: [makeStep(FIELDS)],
  watch: {
    skinThickness() {
      this.showNextStep();
    }
  }
};
</script>
